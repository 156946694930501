import '../scss/app.scss';

//const $ = require("jquery");
require("bootstrap");

global.$ = require("jquery");
global.jQuery = global.$;

$(function () {
    $('[data-toggle="popover"]').popover();

    $('.carousel').carousel({
        interval: false,
        pause: true,
    })

    $('#carousel').on('slid.bs.carousel', function () {
        let activeNumber = $('.carousel-item.active', this).data('index')
        activeNumber = activeNumber < 10 ? 0 + activeNumber.toString() : activeNumber.toString()
        $('.numbers .active-number').text(activeNumber)

        if ($(window).outerWidth() < 1200) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $('#carousel').offset().top - $('.navbar').outerHeight()
            }, 500);
        } else {
            $([document.documentElement, document.body]).animate({
                scrollTop: $('.section--services').offset().top - $('.navbar').outerHeight()
            }, 500);
        }

        $('.carousel').carousel({
            interval: false,
            pause: true,
        })
    })

    $('body').on('click', '.close_hover', function () {
        if ($(window).outerWidth() < 992) {
            $('.text_hover').removeClass('on').addClass('off')
        }
    })

    $('.image img').on('click', function () {
        if ($(window).outerWidth() < 992) {
            $('.close_hover').trigger('click')
            $('.text_hover', $(this).parent()).addClass('on').removeClass('off')
        }
    })

    $('body').on('click', '.section--anwendungsbereiche .circle', function () {
        if ($(this).hasClass('on')) {
            $(this).removeClass('on')
        } else {
            $(this).addClass('on')
        }
    })

    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 100) {
            $('.back-to-top').fadeIn();
        } else {
            $('.back-to-top').fadeOut();
        }
    });

    $('.back-to-top').on('click', function () {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    // $('.nav-item.hash, .dropdown-item').on('click change', function (e) {
    //     if (window.location.hash && window.location.hash != "") {
    //         var hash = window.location.hash
    //         scrollTo(hash)
    //     }
    // });

    if (window.location.hash && window.location.hash != "") {
        var hash = window.location.hash
        scrollTo(hash)
    }

    function scrollTo(hash) {
        hash = hash.replace('#', '');
        var offset = $('a[name="' + hash + '"]').offset()
        if (offset) {
            // var offsetTop = offset.top - $('.navbar').outerHeight()
            var offsetTop = offset.top
            $('html,body').stop().animate({
                scrollTop: offsetTop
            }, 1000);
        }
    }

    $('.short').on('click', 'span', function () {
        $(this).closest('.short').removeClass('on')
        $('.long', $(this).closest('.short').parent()).addClass('on')
    })

    $('.long').on('click', 'span', function () {
        $(this).closest('.long').removeClass('on')
        $('.short', $(this).closest('.long').parent()).addClass('on')
    })

    function createCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    // function readCookie(name) {
    //     var nameEQ = name + "=";
    //     var ca = document.cookie.split(';');
    //     for (var i = 0; i < ca.length; i++) {
    //         var c = ca[i];
    //         while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    //         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    //     }
    //     return null;
    // }
    // // Cookie angleichen
    // if (readCookie('product') != product) {
    //     createCookie('product', product, 356);
    // }
    // if (readCookie('animal') != animal) {
    //     createCookie('animal', animal, 356);
    // }

});
